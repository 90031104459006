/*====================
======GRID-BLOG=======
======================*/

.blog-themes-wrapper {
  padding: 3rem 0;
}

.blog-images-box img {
  width: 100px;
}

.kommentar-box {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
}

.delete_user_kommentar.btn-edit {
    color: #ce0000;
    transition: all 350ms;
}

.delete_user_kommentar.btn-edit:hover {
  color: $color;
}

.btn-edit {
    cursor: pointer;
}

.blog-themes-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr;
  //grid-template-rows: 10rem ;
  grid-gap: 1rem;
  width: 70%;
  margin: 1rem auto;
  overflow: hidden;
  border-radius: 0.3rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.5rem 0.25rem;
}

.blog-beschreibung-box,
.blog-images-box,
.blog-title-box {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.1rem;
}

.blog-button-box,
.blog-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-button-box span,
.blog-title-box span {
  display: block;
  font-weight: bold;
  color: #fff;
  text-align: center;
  transition: all 350ms;
}

.blog-button-box span:hover,
.blog-title-box span:hover {
  color: $orange;
}

img.img-about {
    width: 8rem;
    height: 8rem;
    padding: .1rem;
    border: 2px solid rgba(255,255,255,.5);
}

.blog-button-box span {
  font-size: 1.4rem;
}

.blog-box img {
    margin: .6rem;
}

.about-box div:nth-child(1) {
    border-right: 1px solid rgba(255,255,255,.5);
}

.about-box {
    border: 1px solid rgba(255,255,255,.4);
    border-radius: .3rem;
    margin: 0 .5rem 0 .5rem;
}

.card-blog {
    border: 1px solid rgba(255,255,255,.4);
    border-radius: .5rem;
    padding: 1rem;
}

.archiv-liste {
    border: 1px solid #7a7a7a;
    border-radius: 5px;
    padding: .5rem;
    text-align: center;
    margin-bottom: 1rem;
    //transition: all 250ms;
}

@media screen and (max-width: 1200px) {
  .blog-themes-grid {
    width: 98%;
  }
  .about-box div:nth-child(1) {
    border-right:unset;
  }
}
@media screen and (max-width: 992px) {
  .blog-themes-grid {
    display: block;
  }

  .blog-beschreibung-box,
  .blog-images-box,
  .blog-title-box {
    border-right: unset;
  }

  .blog-inhalt {
    text-align: center;
    margin-top: 1rem;
  }
}
