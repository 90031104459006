.login-box {
    padding: 1rem 2rem;
    border-radius: .3rem;
    border: 1px solid rgba(255,255,255,.3);
    width: 25%;
}

.box-height {
  min-height: 100vh;
}

.member-header {
    border-radius: .3rem;
    border: 1px solid rgba(255,255,255,.5);
    padding: 1rem;
    margin: 2rem 0;
}

.user-startseite .card {
    background-color: transparent;
    border: 1px solid rgba(218,218,218,.125);
}

.user-startseite .btn-link {
    color: #f2f2f2;
    -webkit-transition: all 350ms;
    transition: all 350ms;
}

//############################FILEUPLOAD######################
.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}


/*===============================
=========MENBER BEREICH==========
=================================*/

#img_contain img {
    width: 100%;
}


@media screen and (max-width: 1400px) {
.login-box {
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {
.login-box {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
.login-box {
    width: 95%;
  }
}
