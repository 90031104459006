body {
    font-family: $font1;
    font-weight: 400;
    background-color: $background;
    color: $color;
}

a {
    transition: all .9s ease 0s;
    color: $link;
}

.box-border {
  border: 1px solid rgba(255,255,255,.4);
}

.blog-box img {
  margin: 0.6rem;
}

a:hover {
  text-decoration: none;
  color: $color;
}

.box-wrapper a {
  color: $color;
}

.table-tour .table th {
  color:$color;
}

.table-tour .table th:nth-last-child(1) {
  color:transparent!important;
}

.table-tour .table td, .table-tour .table th {
  border-top: 1px solid rgba(222, 226, 230, 0.22);
}

body section {
    padding: 75px 0;
}

section#start {
  padding: 0;
}
.grey {
    color: #777;
}

.text-theme {
    color: $color;
}

.hr-white-opt {
    margin-top: 0!important;
    margin-bottom: 0!important;
    border: 0;
    border-top: 1px solid rgba(173,173,173,.37);
}

.hr-widget {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    border: 0;
    border-top: 1px solid #666;
}

.about-box hr {
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
  border: 0;
  border-top: 1px solid rgba(173,173,173,.37);
}

#uploadProgressShow {
  display: none;
}

#show_blog hr {
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
  border: 0;
  border-top: 1px solid rgba(173,173,173,.37);
}

#show_blog img {
  border-radius: 0.3rem;
  border: 1px solid rgba(173,173,173,.37);
  padding:.3rem;
}

.hr-red {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    border: 0;
    border-top: 1px solid rgba(229, 0, 0, 0.25);
}

.box-shadow {
    box-shadow: inset -1px -2px 60px 12px rgba(255,255,255,.2);
}

.text-shadow {
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

.add_repeat, .terms {
    display: none;
}

.text-red {
  color: $red;
}

.cc_container .cc_btn {
background-color: #f1d600 !important;
color: #000 !important;
}

.cc_container {
background: #1f6888 !important;
color: #fdfdfd !important;
}
.cc_container a {
color: #31a8f0 !important;
}

#IndexModal .modal-content, #editImageModal .modal-content {
    background-color: rgba(43,43,43,.952941);
    border: 1px solid rgba(255,255,255,.2);
}
#IndexModal .close, #editImageModal .close {
    color: #fff;
    text-shadow: 0 1px 0 rgba(255,255,255,.4);
}

#IndexModal .close:hover, #editImageModal .close:hover {
  color:$red;
  text-shadow: 0 1px 0 rgba(255,255,255,.1);
}
/*================
======NAVBAR======
==================*/
.login-head {
  padding-right: 1rem;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color:white!important;
  background-color: $red;
  border: 1px solid #aaa;
}

.dropdown-item.active, .dropdown-item:active {
  color:white!important;
  background-color: $red;
  border: 1px solid #aaa;
}

.dropdown-divider {
  border-top: 1px solid rgba(255,255,255,.05);
}

nav .dropdown-item {
    background-color: rgba(43,43,43,.952941);
    color: $color;

}
.navbar-expand-xl .navbar-nav .dropdown-menu {
    border: 1px solid rgba(255,255,255,.05);
}

nav .dropdown-item:hover {
  color:white!important;
  background-color: $red;
  border: 1px solid #aaa;
}

nav .dropdown-menu {
    background-color: rgba(43,43,43,.952941);
}

.navbar {
    padding: 0.5rem 0.5rem;
}

.fixed-top {
  border-bottom: 1px solid rgba(255,255,255,.05);
  //hsla(0, 100%, 45%, 0.19);
}

.navbar-light .navbar-toggler {
    color: $color;
    border-color: $red;
}
.bar-icon {
    font-size: 1.5rem;
}

.navbar-brand {
  margin-left: 7rem;
}

.login-head a {
  color:$color;
  transition: all .9s ease 0s;
}

.login-head a:hover {
  color:$red;
}

.bg-light {
    background-color: rgba(43, 43, 43, 0.952941)!important;
    border-bottom:  2px solid rgba(221, 221, 221, 0.16);
}
.nav-link {
    color: $color!important;
    transition: all .9s ease 0s;
    font-weight: 500;
    text-transform: uppercase;
}

.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.6rem;
    padding-left: 0.6rem;
    margin: 0.1rem;
    font-size: 0.95rem;
    font-weight: 600;

}

.nav-link .fa b {
    font-family: $font1;
    font-weight: 500;
    text-transform: uppercase;
}

.nav-link:hover {
  color:white!important;
  background-color: $red;
  border: 1px solid #aaa;
}
