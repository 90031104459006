@media screen and (max-width: 1400px) {
  .navbar-brand {
    margin-left: 0;
  }

  #apd-brand img {
    width: 190px;
    padding-right: 1rem;
    padding-left: 4rem;
  }

  .box {
    margin-top: 4rem;
  }

  .nav-img-sm {
    transform: unset;
  }

  .kommentar-wrapper {
    width: 80%;
    margin: 0 10%;
  }
}
@media screen and (max-width: 1200px) {
  .navbar-brand {
    margin: 0;
  }

  .box:nth-child(1) {
    background-size: unset;
    width: 100%;
    min-height: 60vh;
  }

  .box-wrapper {
    padding-top: 10rem;
    padding-bottom: 1rem;
  }

  .navbar-toggler {
    position: absolute;
    top: 0.8rem;
  }
}
@media screen and (max-width: 992px) {
  .box:nth-child(1) {
    background-image: none;
  }

  .sm-background.d-none {
    display: block!important;
  }

  .wrapper {
    display: block;
  }

  .box-wrapper {
    padding-top: 1.5rem;
  }

  .single-service i {
    font-size: 2rem;
    line-height: 3rem;
    height: 1rem;
  }

  .single-service {
    padding: 0.5rem 0.3rem;
    min-height: unset;
  }

  .single-service:hover i {
    transform: scale(1.3);
  }
}
@media screen and (max-width: 768px) {
  .single-service {
    margin: 0.5rem;
    min-height: unset;
  }

  .kommentar-wrapper {
    //min-height: auto;
    width: 100%;
    margin: 0;
  }

  #footer-end {
    padding: 0.5rem 0;
    border-bottom: 2px solid rgba(221,221,221,.16);
  }
}
