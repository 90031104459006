.content-wrapper {
  padding: 7rem 0 0;
}

.alben-box {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  border: 1px solid rgba(255,255,255,0.2);
  padding: 2rem;
}

.galerie-services {
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 300px;
  margin-top: 10px;
  padding: 25px;
  transition: all 0.9s ease 0s;
  border-radius: 0.3rem;
  border: 1px solid rgba(255,255,255,0.2);
}

.galerie-services:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.galerie-services h4 {
  color: #e50000;
  font-weight: 700;
  font-size: 1.2rem;
  padding-top: 0.5rem;
}

.galerie-date {
  font-size: 14px;
  font-weight: 700;
}

h4 small {
  line-height: 1;
  color: #999;
}

.prem {
  color: #337ab7;
}

.galerie-services p {
  color: #E0E0E0;
  font-size: 0.9rem;
}

.galerie-services img {
  border-radius: 0.3rem;
  border: 1px solid rgba(255,255,255,0.2);
  padding: 0.2rem;
}
/*======================
======GRID-GALERIE======
========================*/
.galerie-grid-wrapper {
  display: grid;
  min-height: 65vh;
  margin: auto;
}

.galerie-wrapper {
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  justify-self: center;
  margin: 2rem 0;
  width: 60%;
}

.galerie-grid ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-auto-rows: 180px;
  grid-auto-flow: dense;
  grid-gap: 0.6rem;
  margin: 2.5rem;
}

.galerie-grid img {
  display: block;
  border-radius: 0.3rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.pag-galerie-top {
  padding: 2rem 0 0 2rem;
}

.pag-galerie-bottom {
  padding-left: 2rem;
}
@media screen and (max-width: 1400px) {
  //galerie
  .galerie-grid-wrapper .galerie-wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 1200px) {
  .galerie-grid-wrapper .galerie-wrapper {
    width: 95%;
  }
}
@media screen and (max-width: 768px) {
  .pag-galerie-top {
    padding: 2rem 0 0 0.5rem;
  }

  .pag-galerie-bottom {
    padding-left: 0.5rem;
  }
  .content-wrapper {
    padding: 2rem 0 0;
  }
}
