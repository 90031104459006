/*=====================
======CAROUSEL CSS=====
=======================*/

#team-carousel {
	position:relative
}

#info {
	border-top: 2px solid rgba(221, 221, 221, 0.16);
	color:#E0E0E0;
}

#info h2 {
	color:#E0E0E0;
	margin-bottom:35px;
}


  .single-member {
    	min-height:22rem;
      background-color: rgba(64, 64, 64, 0.33);
      border: 1px solid rgba(150, 150, 150, 0.42);
      padding: .7rem;
      text-align: center;
      border-radius: 0.5rem;
      overflow: hidden;
      transition: all 0.9s ease 0s;
  }

.single-member:hover {
  background-color: rgba(34, 34, 34, 0.5);

  //border: 1px solid rgba(230,0,0,.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: inset -1px -2px 60px 12px rgba(255,255,255,.2);
  color:#E0E0E0!important;
}
#info .socials i {
	background-color:#fff;
	border-radius: 50%;
	color: #43484E;
	display: inline-block;
	font-size:16px;
	height: 30px;
	line-height:28px;
	margin-right: 5px;
	text-align: center;
	width: 30px;
	border-width:1px;
	border-color:#43484E;
	border-style: solid;
	opacity:.5;
	 -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

#info .socials a i:hover {
	opacity:1;
	color:#fff;
	border:0
}

#info .socials a .fa-facebook:hover {
	background: #3B5997;
}
#info .socials a .fa-envelope-o:hover {
  background:#C61118
}


#info .socials a .fa-twitter:hover {
  background:#29C5F6
}

#info .socials a .fa-pinterest:hover {
  background:#C61118
}

#info .socials a .fa-dribbble:hover {
  background:#FF5C93
}

#info .socials a .fa-linkedin:hover {
  background:#006DC0
}

#info .socials a .fa-google-plus:hover {
  background:#D13D2F
}

#info .socials a .fa-flickr:hover {
  background:#7A797B
}

.member-left, .member-right {
	position:absolute;
	top:25px;
	color:#43484E
}


.member-left {
	left:47%;
}

.member-right {
	left:53%
}

.single-member h4 {
    color: #e30613;
    font-size: 1.2rem;
    padding-top:0.7rem;
    line-height: 1;
    text-shadow: 0 1px 2px rgba(0,0,0,.3);
 }

.single-member h5 {
font-size: 1rem;
text-shadow: 0 1px 2px rgba(0,0,0,.6);
}


#carouselBandControls {
  padding-top: 5rem;
}

.carousel-control-next, .carousel-control-prev {
     align-items:baseline;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
opacity: 0.2;
transition: all 450ms;
}

.carousel-control-next-icon:hover, .carousel-control-prev-icon:hover {
opacity: 0.8;
}
