//startseite

.box {
  margin-top: 6.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.box:nth-child(1) {
  background-image: url("../images/start/gottschalk-3.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.back-small {
  background-image: url("../images/content/back-content-b&w.jpg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 90%;
}

.wrapper {
  display:flex;
  align-items:center;
}

.single-service {
    background-color: rgba(0, 0, 0, 0.39);
    padding: 25px;
    transition: all 0.9s ease 0s;
    border-radius: 0.5rem;
    border: 1px solid rgba(230, 0, 0, 0.3);
    min-height: 20rem;
}

.box-wrapper {
  text-align: center;
  padding-top:15rem;
}

.single-service i {
    text-align: center;
    font-size: 80px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    display: inline-block;
    height: 150px;
    line-height: 150px;
    width: 150px;
    color: #aaa;
    transition: all 0.9s ease 0s;
}

.single-service:hover {
	background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(255,255,255,0.5);
}

.single-service:hover i {
	color: rgba(227, 6, 19, 0.8);
	transform: scale(1.1) rotate(360deg);

}

.quo-header {
    font-size: 1rem;
    font-weight: 600;
    color: #E0E0E0;
}

#info h6 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #e50000;
    text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

#news, #kunden, #contact, #footer-links, #footer-end {
    border-top: 4px solid rgba(221, 221, 221, 0.16);
}

#news h5 {
  font-size: 1.3rem;
}

#news small {
  font-size:0.95rem;
}

.nav-img-sm {
  width: 130px;
  transform: translate(-2em,0);
}

.kommentar-wrapper {
 width: 60%;
 margin: 0 20%;
 border-radius: 0.3rem;
 border: 1px solid rgba(255,255,255,0.3);
 padding: 1rem 1.5rem;
 background-color: rgba(64,64,64,.33);
 min-height: 46vh;
}

#carouselMessageControls.carousel {
  padding:2rem 0 1rem 0;
}

#carouselMessageControls .member-left, #carouselMessageControls .member-right {
  top:-1rem;
}

.msg-img {
  padding-bottom:1rem;
}

.usr-img {
  height: 130px!important;
  width:130px!important;
  border-radius: 50%;
  border:1px solid rgba(255,255,255,0.3);
  padding: 0.5rem;
}

blockquote small, blockquote .small {
    display: block;
    line-height: 1.428571429;
    color: #999;
    font-size: 1rem;
}

blockquote small:before, blockquote .small:before {
    content: '\2014 \00A0';
}

blockquote:before, blockquote:after {
    content: "";
}
blockquote p.message  {
	border-left:4px solid rgba(255,255,255,0.15);
	padding-left:1.2rem;
}

#footer-links img, #footer-end img {
  width: 100px;
}

#footer-end {
  padding:2.5rem;
  border-bottom: 4px solid rgba(221, 221, 221, 0.16);
}

.footer-item {
  padding: 0 0.2rem;
}
